import * as React from "react";
import videojs from "video.js";

// Styles
import "video.js/dist/video-js.css";

type VideoPlayerProps = {
  options?: videojs.PlayerOptions;
  onReady?: (player: VideoPlayer) => void;
}

const initialOptions: videojs.PlayerOptions = {
  techOrder: ["html5"],
  controls: false,
  fluid: true,
  bigPlayButton: false,
  disablePictureInPicture: true,
  playsinline: true,
  controlBar: {
    fullscreenToggle: false,
    progressControl: false,
    remainingTimeDisplay: false,
    playToggle: false,
    pictureInPictureToggle: false,
    volumePanel: false
  }
};

class VideoPlayer extends React.Component<VideoPlayerProps> {
  videoRef: React.RefObject<HTMLVideoElement>;
  elementRef: React.RefObject<HTMLLIElement>;
  player?: videojs.Player;

  constructor(props: VideoPlayerProps) {
    super(props);
    this.videoRef = React.createRef<HTMLVideoElement>();
    this.elementRef = React.createRef<HTMLLIElement>();
  }

  public videojs() {
    return this.player;
  }

  fireReady() {
    if (this.props.onReady) {
      this.props.onReady(this);
    }
  }

  element() {
    return this.elementRef.current!;
  }

  componentDidMount() {
    const that = this;
    videojs(this.videoRef.current!, {
      ...initialOptions,
      ...this.props.options
    }).ready(function() {
      that.player = this;
      that.fireReady();
    });
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    return (
      <li ref={this.elementRef} className="player">
        <video ref={this.videoRef} className="video-js" tabIndex={-1} />
      </li>
    );
  }
};

export default VideoPlayer;
