import React from 'react';
import $ from 'jquery';
import videos from './videos.json';
import { domElement, bindFunctions, now, fadeOutAndHide, displayAndFadeIn } from './common';
import { on_iOS } from './ios';
import { trackEvent } from './tracking';
import { Group, VideoGroup } from  './group';
import { Players } from './players';

export let simultan: Simultan;

function parseRatio(ratio: string|number): number {
  let match;
  if (typeof ratio === "number") {
    return ratio;
  } else if ((match = ratio.match(/^(0|(?:[1-9][0-9]*))[:/](0|(?:[1-9][0-9]*))$/))) {
    return parseInt(match[1]) / parseInt(match[2]);
  } else if ((match = ratio.match(/^(?:0|(?:[1-9][0-9]*))(?:[.][0-9]+)?$/))) {
    return parseFloat(match[0]);
  } else {
    return NaN;
  }
}

function tileWidth(areaWidth: number, areaHeight: number, nPlayers: number, aspectRatio: number): number {
  function fittedWidth(columns: number): number {
    let rows = Math.ceil(nPlayers / columns);
    let width = areaWidth / columns;
    let height = rows * width / aspectRatio;
    return height <= areaHeight ? width : width * areaHeight / height;
  }
  const idealWidth = Math.min(areaWidth, Math.sqrt(areaHeight * areaWidth * aspectRatio / nPlayers));
  const idealColumns = Math.floor(areaWidth / idealWidth);
  let width1 = fittedWidth(idealColumns);
  let width2 = fittedWidth(idealColumns + 1);
  return Math.max(width1, width2);
}

export function setPlayerWidths() {
  let nPlayers = $('.player').length;
  let playerWidth = tileWidth(simultan.fieldWidth!, simultan.fieldHeight!, nPlayers, simultan.videoAspectRatio);
  $('.player').width(playerWidth);
}

function setGroupPositions() {
  let $body = $('body');
  let bodyMargin = ($body.outerHeight(true)! - $body.height()!) / 2;
  $('.groups').each((_index, groups) => {
    let groupsInPartition = $(groups).find('.group');
    let groupButtonContainer = groupsInPartition.find('.groupTitle');
    let groupCircleSize = groupButtonContainer.outerHeight()!;
    let verticalSpace = simultan.fieldHeight! + 2 * bodyMargin;
    let unusedGroupSpace = verticalSpace - groupCircleSize;
    groupsInPartition.each((index, group) => {
      let groupFraction = index / (groupsInPartition.length - 1);
      let $group = $(group);
      let groupPosition = unusedGroupSpace * groupFraction;
      $group.css({
        "top": `${groupPosition}px`
      })
      if (index > 0) {
        let $flyout = $group.find('.flyout');
        let flyoutHeight = $flyout.outerHeight()!;
        let unusedFlyoutSpace = verticalSpace - flyoutHeight;
        let flyoutTop = Math.max(0, unusedFlyoutSpace * groupFraction);
        $flyout.css({
          "top": `${flyoutTop - groupPosition}px`
        });
      }
    });
  });
}

const iOS_restartInterval = 2500;

type SimultanProps = {
  videoGroups: VideoGroup[];
}

export class Simultan extends React.Component<SimultanProps> {
  groups: React.RefObject<Group>[];
  players: React.RefObject<Players>;
  videoAspectRatio: number;
  fieldWidth?: number;
  fieldHeight?: number;
  field?: HTMLElement;
  info?: HTMLIFrameElement;
  infoButton?: HTMLElement;
  infoShowing: number;
  isPaused?: boolean;
  pendingClickEvent?: React.SyntheticEvent;
  on_iOS: boolean;
  iOS_unmuteElement?: HTMLElement;
  fieldClickInterval?: NodeJS.Timer;

  constructor(props: SimultanProps) {
    super(props);
    simultan = this;
    this.groups = this.props.videoGroups.map(() => React.createRef<Group>());
    this.players = React.createRef<Players>();
    this.videoAspectRatio = parseRatio(videos.aspectRatio);
    bindFunctions(this, "windowResized", "windowClosing", "fieldClicked", "visibilityChanged");
    this.infoShowing = -1;
    this.on_iOS = on_iOS();
  }

  log(message: string) {
    const log = $('#log');
    log.addClass("active");
    log.append($('<p/>').append(new Date().toISOString() + " – " + message));
    log.scrollTop(log.prop("scrollHeight"));
  }

  addActiveGroup(group: Group) {
    return this.players.current!.addPlayer(group.props.index);
  }

  removeActiveGroup(group: Group) {
    return this.players.current!.removePlayer(group.props.index);
  }

  activeGroups() {
    return this.groups
      .map((groupRef) => groupRef.current!)
      .filter((group) => group.isActive())
  }

  activePlayers() {
    return this.activeGroups()
      .map((group) => group.player());
  }

  nActivePlayers() {
    return this.activeGroups().length;
  }

  paused(isPaused?: boolean) {
    if (isPaused !== undefined) {
      if (isPaused) {
        this.isPaused = true;
        if (this.on_iOS) {
          clearInterval(this.fieldClickInterval);
          this.fieldClickInterval = undefined;
        }
      } else {
        this.isPaused = false;
        if (this.on_iOS) {
          clearInterval(this.fieldClickInterval);
          this.fieldClickInterval = setInterval(this.fieldClicked, iOS_restartInterval);
        }
      }
    }
    return this.isPaused;
  }

  visible() {
    return document.visibilityState !== 'hidden';
  }

  visibilityChanged() {
    if (this.infoShowing < 0) {
      if (this.visible()) {
        trackEvent("Window", "focus", "window");
        this.players.current!.resume();
      } else {
        trackEvent("Window", "unfocus", "window");
        this.players.current!.pause();
      }
    }
  }

  componentDidMount() {
    this.field = domElement('#field');
    this.info = domElement<HTMLIFrameElement>('#info');
    this.infoButton = domElement('#infoButton');
    window.addEventListener('resize', this.windowResized);
    window.addEventListener('beforeunload', this.windowClosing);
    document.addEventListener('visibilitychange', this.visibilityChanged);
    this.paused(false);
    if (this.on_iOS) {
      this.field.addEventListener('click', this.fieldClicked);
      this.iOS_unmuteElement = domElement('#iOS-unmute');
    }
    document.fonts.ready.then(this.windowResized);
    if (document.location.hash === "#info") {
      window.history.replaceState(undefined, "", document.location.pathname);
      this.infoButtonClicked();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResized);
  }

  windowResized() {
    this.fieldWidth = $('#field').innerWidth();
    this.fieldHeight = $('#field').innerHeight();
    setPlayerWidths();
    setGroupPositions();
  }

  windowClosing() {
    trackEvent("Window", "close", "window");
  }

  pauseAndFadeOutPlayers() {
    return this.players.current!.pauseAndFadeOut();
  }

  fadeInAndResumePlayers() {
    return new Promise<void>((resolve, _reject) => {
      this.removeInfo().then(() => {
        this.players.current!.fadeInAndResume().then(() => {
          resolve();
        });
      });
    });
  }

  loadInfo() {
    return new Promise<void>((resolve, _reject) => {
      if (!this.info!.src) {
        this.info!.addEventListener("load", () => {
          resolve();
        })
        this.info!.src = "info.html";
      } else {
        resolve();
      }
    });
  }

  removeInfo() {
    return new Promise<void>((resolve, _reject) => {
      this.infoButton!.classList.remove("active");
      this.info!.classList.remove("fade");
      this.field!.classList.remove("show-info");
      this.infoShowing = -1;
      resolve();
    });
  }

  infoButtonClicked(event?: React.SyntheticEvent) {
    if (this.infoShowing < 0) {
      trackEvent("Info", "open", "info");
      this.infoButton!.classList.add("active");
      this.infoShowing = 0;
      this.pauseAndFadeOutPlayers().then(() => {
        if (this.on_iOS) {
          this.hideIOSUnmute();
        }
        this.loadInfo();
      }).then(() => {
        displayAndFadeIn(this.info!, () => {
          this.field!.classList.add("show-info");
        }, () => {
          this.info!.classList.add("fade");
        });
      }).then(() => {
        this.infoShowing = 1;
        this.handlePendingClickEvent();
      });
    } else if (this.infoShowing > 0) {
      trackEvent("Info", "close", "info");
      this.infoButton!.classList.remove("active");
      this.infoShowing = 0;
      fadeOutAndHide(this.info!, () => {
        this.info!.classList.remove('fade');
      }, () => {
        this.field!.classList.remove("show-info");
      }).then(() => {
        this.fadeInAndResumePlayers();
      }).then(() => {
        this.infoShowing = -1;
        this.handlePendingClickEvent();
      });
    } else {
      this.pendingClickEvent = event;
    }
    if (event) {
       event.preventDefault();
    }
  }

  handlePendingClickEvent() {
    const pendingClickEvent = this.pendingClickEvent;
    this.pendingClickEvent = undefined;
    if (pendingClickEvent) {
      this.infoButtonClicked(pendingClickEvent);
    }
  }

  fieldClicked() {
    if (this.on_iOS) {
      if (!this.paused()) {
        const updateLimit = now() - iOS_restartInterval * 4;
        this.activeGroups().forEach((group) => {
          const player = group.player();
          if (group.lastTimeUpdate && (player.paused() || group.lastTimeUpdate < updateLimit)) {
            player.play();
          } else if (player.muted()) {
            player.muted(false);
          }
        });
        this.hideIOSUnmute();
      }
    }
  }

  showIOSUnmute() {
    this.iOS_unmuteElement!.classList.add('active');
  }

  hideIOSUnmute() {
    this.iOS_unmuteElement!.classList.remove('active');
  }

  render() {
    return (
      <>
        <div id="header">
          <span id="composer">Willem Schulz</span>
          <span id="title">simultan</span>
          <span id="captionAndInfo">
            <span id="caption">— ein Computerspiel mit Neuer Musik</span>
            <a
              id="infoButton"
              href="info.html"
              tabIndex={1}
              accessKey="i"
              onClick={(event) => this.infoButtonClicked(event)}
            >
              <u>I</u>nfo
            </a>
          </span>
        </div>
        <div id="log"></div>
        <ol className="groups left">
          {Array.from({length: (this.props.videoGroups.length + 1) / 2}).map((_group, i, _array) => {
            const index = i * 2;
            return (
              <Group
                ref={this.groups[index]}
                key={index}
                index={index}
                videoGroup={this.props.videoGroups[index]} />
            )
          })}
        </ol>
        <ol className="groups right">
          {Array.from({length: this.props.videoGroups.length / 2}).map((_group, i, _array) => {
            const index = i * 2 + 1;
            return (
              <Group
                ref={this.groups[index]}
                key={index}
                index={index}
                videoGroup={this.props.videoGroups[index]} />
            )
          })}
        </ol>
        <div id="field">
          <Players
            ref={this.players}
          />
          <div id="iOS-unmute">Ton aktivieren</div>
          <iframe
            id="info" title="info"
            tabIndex={2}
          />
        </div>
      </>
    );
  }
}
