import React from 'react';
import VideoPlayer from './video';
import { domElement, fadeOutAndHide, displayAndFadeIn, now } from './common';
import { simultan } from './simultan';

type PlayersProps = {
};

type PlayersState = {
  keys: number[];
}

export class Players extends React.Component<PlayersProps, PlayersState> {
  playersElement?: HTMLElement;
  playerRefs: React.RefObject<VideoPlayer>[];
  pendingPromiseResolvers: { [key: number]: { resolve: (videoPlayer: VideoPlayer) => void }[]};

  constructor(props: PlayersProps) {
    super(props);
    this.state = { keys: [] };
    this.playerRefs = [];
    this.pendingPromiseResolvers = {};
  }

  componentDidMount() {
    this.playersElement = domElement('#players');
  }

  addPlayer(key: number) {
    return new Promise<VideoPlayer|undefined>((resolve, _reject) => {
      if (!this.state.keys.includes(key)) {
        this.playerRefs.push(React.createRef<VideoPlayer>());
        if (!(key in this.pendingPromiseResolvers)) {
          this.pendingPromiseResolvers[key] = [];
        }
        this.pendingPromiseResolvers[key].push({ resolve });
        this.setState({ keys: this.state.keys.concat(key) });
      } else {
        resolve(undefined);
      }
    });
  }

  playerAdded(videoPlayer: VideoPlayer, key: number) {
    (this.pendingPromiseResolvers[key] || []).forEach((pendingPromiseResolver) => {
      pendingPromiseResolver.resolve(videoPlayer);
    });
    delete this.pendingPromiseResolvers[key];
  }

  removePlayer(key: number) {
    return new Promise<boolean>((resolve, _reject) => {
      if (this.state.keys.includes(key)) {
        this.setState({ keys: this.state.keys.filter((playerKey) => (playerKey !== key)) }, () => {
          resolve(true);
        });
      } else {
        resolve(false);
      }
    });
  }

  pause() {
    const playingGroups = simultan.groups
      .filter((group) => group.current!.isActive())
      .filter((group) => !group.current!.player().paused());
    simultan.paused(true);
    playingGroups.forEach((group) => {
      group.current!.player().pause();
    });
  }

  resume() {
    const pausedGroups = simultan.groups
      .filter((group) => group.current!.isActive())
      .filter((group) => group.current!.player().paused());
    simultan.paused(false);
    pausedGroups.forEach((group) => {
      group.current!.lastTimeUpdate = now();
      group.current!.player().play();
    });
  }

  pauseAndFadeOut() {
    return new Promise<void>((resolve, _reject) => {
      this.pause();
      if (this.state.keys.length > 0) {
        fadeOutAndHide(this.playersElement!, () => {
          this.playersElement!.classList.add('fade');
        }, () => {
        }).then(() => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  fadeInAndResume() {
    return new Promise<void>((resolve, _reject) => {
      let showPlayers;
      if (this.playersElement!.classList.contains("fade")) {
        showPlayers = () => displayAndFadeIn(this.playersElement!, () => {}, () => {
          this.playersElement!.classList.remove("fade");
        });
      } else {
        showPlayers = () => Promise.resolve();
      }
      showPlayers().then(() => {
        if (simultan.visible()) {
          this.resume();
        }
      });
      resolve();
    });
  }

  render() {
    return (
      <ol id="players">
        {this.state.keys.map((key, index) =>
          <VideoPlayer
            key={key}
            ref={this.playerRefs[index]}
            onReady={(videoPlayer) => this.playerAdded(videoPlayer, key)} />
        )}
      </ol>
    );
  }
}
