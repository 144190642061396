
export type PathComponent = {
  name: string;
  path?: string;
}

export function pathName(component: PathComponent): string {
  const patterns = /([^0-9A-Za-zÄÖÜäöüß+&-])|(ä)|(ö)|(ü)|(Ä)|(Ö)|(Ü)|(ß)|([+&])/g;
  const replacements = [ "_", "ae", "oe", "ue", "Ae", "Oe", "Ue", "ss", "und" ];
  return component.path ||
    component.name
      .toLowerCase()
      .replace(patterns, (_match, ...groups) => {
        for (let i in replacements) {
          if (groups[i]) {
            return replacements[i];
          }
        }
        return "";
      })
      .replace(/[_-][_-]+/, "_")
      .replace(/^_/, "")
      .replace(/_$/, "");
}

export function domElement<E extends HTMLElement>(selector: string) {
  return document.querySelector<E>(selector)!;
}

export function bindFunctions(object: any, ...funcNames: string[]) {
  for (let funcName of funcNames) {
    object[funcName] = object[funcName].bind(object);
  }
}

export function now() {
  return new Date().getTime();
}

export function displayAndFadeIn<E extends HTMLElement>(element: E, display: (element: E) => void, fadeIn: (element: E) => void) {
  return new Promise<void>((resolve, _reject) => {
    display(element);
    setTimeout(() => {
      element.addEventListener("transitionend", () => {
        resolve();
      }, { once: true });
      fadeIn(element);
    }, 100);
  });
}

export function fadeOutAndHide<E extends HTMLElement>(element: E, fadeOut: (element: E) => void, hide: (element: E) => void) {
  return new Promise<void>((resolve, _reject) => {
    element.addEventListener("transitionend", () => {
      setTimeout(() => {
        hide(element);
        resolve();
      }, 100);
    }, { once: true });
    fadeOut(element);
  });
}
