import React from 'react';
import videos from './videos.json';
import { PathComponent, pathName } from './common';
import { VideoGroup } from './group';
import { simultan } from './simultan';

type Hash<T> = {
  [key: string]: T;
}

function mimetype(extension: string): string|undefined {
  const mimetypes: Hash<string> = {
    "mp4": "video/mp4"
  }
  return mimetypes[extension];
}

type SourceObject = {
    src: string;
    type?: string;
}

export type VideoClip = PathComponent & {
  name: string,
  volume?: number,
} & ({
  qualities?: string[];
  extension: string;
} | {
  url: string;
  type: string;
});

export function clipSources(clip: VideoClip, group: VideoGroup): SourceObject[] {
  if ("url" in clip) {
    return [{
      src: clip.url,
      type: clip.type
    }];
  } else {
    const groupName = pathName(group);
    const clipName = pathName(clip);
    const type = mimetype(clip.extension);
    if (clip.qualities) {
      let fieldWidth = simultan.fieldWidth!;
      let bestQuality, bestWidthDifference : number|undefined;
      clip.qualities.forEach((quality) => {
        let qualityStringMatch = quality.match(/^[1-9][0-9]+/);
        if (qualityStringMatch != null) {
          let qualityWidth = parseInt(qualityStringMatch[0]) * simultan.videoAspectRatio;
          let widthDifference = Math.abs(Math.log(fieldWidth * 0.7) - Math.log(qualityWidth));
          if (bestWidthDifference === undefined || widthDifference < bestWidthDifference) {
            bestWidthDifference = widthDifference;
            bestQuality = quality;
          }
        }
      });
      if (bestQuality) {
        return [{
          src: `${videos.path}/${groupName}/${clipName}/${groupName}.${clipName}.${bestQuality}.${clip.extension}`,
          type: type
        }];
      } else {
        return clip.qualities.map((quality) => ({
          src: `${videos.path}/${groupName}/${clipName}/${groupName}.${clipName}.${quality}.${clip.extension}`,
          type: type
        }));
      }
    } else {
      return [{
        src: `${videos.path}/${groupName}/${clipName}/${groupName}.${clipName}${clip.extension}`,
        type: type
      }];
    }
  }
}

type ClipProps = {
  clip: VideoClip;
  index: number;
  active: boolean;
  onClick: () => void;
};

export class Clip extends React.Component<ClipProps> {
  render() {
    const active = this.props.active;
    return (
      <li className={"clip" + (active ? " active" : "")}>
        <button
          className="clipTitle"
          tabIndex={this.props.index + 4}
          onClick={() => this.props.onClick()}
        >
          <span className="state">
          </span>
          <span className="text">
            {this.props.clip.name}
          </span>
        </button>
      </li>
    );
  }
}
