import React from 'react';
import VideoPlayer from './video';
import { PathComponent, pathName, bindFunctions, now } from './common';
import { trackEvent } from './tracking';
import { VideoClip, Clip, clipSources } from './clip';
import { simultan, setPlayerWidths } from './simultan';

export type VideoGroup = PathComponent & {
  name: string,
  clips: VideoClip[];
}

type GroupButtonOverlayState = {
  playing: boolean;
  remainingTime?: number;
  duration?: number;
}

class GroupButtonOverlay extends React.Component<any, GroupButtonOverlayState> {
  render() {
    return (<div className="overlay" style={this.state && this.state.playing ? {
      opacity: this.state.remainingTime! / this.state.duration!
    } : undefined} />);
  }
}

type GroupProps = {
  index: number,
  videoGroup: VideoGroup;
}

type GroupState = {
  activeClip?: number;
  buttonLoaded: boolean;
}

export class Group extends React.Component<GroupProps, GroupState> {
  overlayRef: React.RefObject<GroupButtonOverlay>;
  rootRef: React.RefObject<HTMLLIElement>;
  videoPlayer?: VideoPlayer;
  lastTimeUpdate?: number;

  constructor(props: GroupProps) {
    super(props);
    this.state = { activeClip: undefined, buttonLoaded: false };
    this.overlayRef = React.createRef<GroupButtonOverlay>();
    this.rootRef = React.createRef<HTMLLIElement>();
    bindFunctions(this, "dataLoaded", "playPaused", "playResumed", "volumeChanged", "timeUpdated", "stopPlaying");
  }

  isActive() {
    return !!this.videoPlayer;
  }

  playerElement() {
    return this.videoPlayer!.element();
  }

  player() {
    return this.videoPlayer!.videojs()!;
  }

  dataLoaded() {
    this.lastTimeUpdate = now();
  }

  playPaused() {
    if (!simultan.paused()) {
      const player = this.player();
      if (simultan.on_iOS) {
        if (!player.ended()) {
          player.muted(true);
        } else {
          player.muted(true);
        }
      }
      player.play();
    }
  }

  playResumed() {
    if (simultan.paused()) {
      this.player().pause();
    }
  }

  volumeChanged() {
    if (this.state.activeClip !== undefined) {
      const player = this.player();
      player.muted(false);
      player.volume(this.props.videoGroup.clips[this.state.activeClip!].volume || 1.0);
    }
  }

  timeUpdated() {
    this.lastTimeUpdate = now();
    const player = this.player();
    this.overlayRef.current!.setState({
      playing: true,
      remainingTime: player.remainingTime(),
      duration: player.duration(),
    });
  }

  startPlaying() {
    const clip = this.props.videoGroup.clips[this.state.activeClip!];
    const player = this.player();
    const playerElement = this.playerElement();
    const clipTitle = this.props.videoGroup.name + " – " + clip.name;
    trackEvent("Video", "play", clipTitle, this.state.activeClip === undefined ? 0 : 1);
    this.lastTimeUpdate = undefined;
    player.disablePictureInPicture(true);
    playerElement.title = clipTitle;
    this.volumeChanged();
    if (simultan.on_iOS) {
      if (simultan.nActivePlayers() > 1) {
        player.muted(true);
      }
    }
    this.player().autoplay(true);
    player.one("ended", this.stopPlaying);
    player.on("timeupdate", this.timeUpdated);
    player.on("loadeddata", this.dataLoaded)
    player.on("pause", this.playPaused);
    player.on("play", this.playResumed);
    player.on("volumechange", this.volumeChanged);
    player.src(clipSources(clip, this.props.videoGroup));
  }

  stopPlaying(event?: React.SyntheticEvent) {
    if (this.state.activeClip !== undefined) {
      const player = this.player();
      const playerElement = this.playerElement();
      trackEvent("Video", "stop", playerElement.title, event && event.type === "ended" ? 0 : 1);
      this.lastTimeUpdate = undefined;
      player.off("ended", this.stopPlaying);
      player.off("loadeddata", this.dataLoaded)
      player.off("pause", this.playPaused);
      player.off("volumechange", this.volumeChanged);
      player.off("play", this.playResumed);
      player.off("timeupdate", this.timeUpdated);
      this.overlayRef.current!.setState({
        playing: false,
      });
      playerElement.title = "";
      this.setState({ activeClip: undefined });
      this.videoPlayer = undefined;
      simultan.removeActiveGroup(this).then((removed) => {
        if (removed) {
          setPlayerWidths();
        }
      });
    }
  }

  clipClicked(index: number) {
    if (index !== this.state.activeClip) {
      simultan.fadeInAndResumePlayers().then(() => {
        this.setState({ activeClip: index }, () => {
          simultan.addActiveGroup(this).then((newVideoPlayer) => {
            if (newVideoPlayer) {
              this.videoPlayer = newVideoPlayer;
              setPlayerWidths();
            }
            this.startPlaying();
          });
        });
        this.mouseLeft();
      });
    }
  }

  groupClicked() {
    if (this.isActive()) {
      simultan.fadeInAndResumePlayers().then(() => {
        this.stopPlaying();
      });
    }
  }

  groupButtonLoaded(_event: React.SyntheticEvent) {
    setTimeout(() => {
      this.setState({ buttonLoaded: true });
    }, Math.random() * 2000);
  }

  groupTitleClicked() {
    (this.rootRef.current!.querySelector(".groupButton") as HTMLButtonElement).focus();
  }

  unFocus() {
    this.rootRef.current!.querySelectorAll("button").forEach((button) => {
      button.blur();
    })
  }

  mouseEntered() {
    this.rootRef.current!.classList.add("hover");
  }

  mouseLeft() {
    this.rootRef.current!.classList.remove("hover");
    this.unFocus();
  }

  render() {
    const activeClass = this.state.activeClip !== undefined ? " active" : "";
    const alignment = this.props.index % 2 ? " right" : " left";
    const groupName = pathName(this.props.videoGroup);
    const loading = this.state.buttonLoaded ? "" : " buttonLoading";
    let firstClipIndex = 0;
    for (let i = 0; i < this.props.index; ++i) {
      firstClipIndex += simultan.props.videoGroups[i].clips.length;
    }
    return (
      <li
        ref={this.rootRef}
        className={"group" + alignment + activeClass + loading}
        id={`group-${groupName}`}
        onMouseEnter={() => this.mouseEntered()}
        onMouseLeave={() => this.mouseLeft()}
      >
        <div className="groupTitle" onClick={() => { this.groupTitleClicked(); }}>
          <button className="groupButton"
            onClick={() => this.groupClicked()}
            tabIndex={this.props.index + firstClipIndex + 3}
          >
            <img
              src={`img/${groupName}.png`}
              alt=""
              onLoad={(event) => this.groupButtonLoaded(event)}
            />
            <GroupButtonOverlay ref={this.overlayRef} />
          </button>
        </div>
        <div className="flyout">
          <div className="groupTitle text"
            onClick={() => { this.groupClicked(); this.groupTitleClicked(); }}
          >
            <span>{this.props.videoGroup.name}</span>
          </div>
          <ol className="clips">
            {this.props.videoGroup.clips.map((clip, index) =>
              <Clip
                key={index}
                clip={clip}
                index={this.props.index + firstClipIndex + index}
                active={index === this.state.activeClip}
                onClick={() => this.clipClicked(index)} />
            )}
          </ol>
        </div>
      </li>
    );
  }
}
