
declare global {
  interface Window {
    _paq: any;
  }
}

export function trackEvent(category: string, action: string, name?: string, value?: number) {
  if (window._paq) {
    window._paq.push(['trackEvent', category, action, name, value]);
  }
}
